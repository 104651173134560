<template>
  <b-modal
    :title="update ? 'UPDATE EXPENSE' : 'CREATE EXPENSE'"
    size="lg"
    centered
    modal
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    :ok-title="update ? 'Update' : 'Save'"
    v-model="isActive"
    @ok="handleOk"
    @hidden="handleHidden"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col sm="5">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Account">
              <b-form-input
                v-model="expense.account"
                :state="errors.length > 0 || accountFindError ? false : null"
              />
              <b-form-invalid-feedback>
                User not Found.
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col class="d-flex justify-content-center align-items-center" sm="1">
          <b-button
            class="btn-icon rounded-circle"
            variant="outline-primary"
            @click="findAccount"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col sm="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Client Name">
              <b-form-input
                v-model="expense.client"
                :state="errors.length > 0 ? false : null"
                disabled
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="10">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Description">
              <b-select
                :options="descriptionOpts"
                v-model="expense.description"
                :state="errors.length > 0 ? false : null"
                :disabled="descriptionOpts.length < 1"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col class="d-flex justify-content-center align-items-center" sm="2">
          <b-button
            class="text-nowrap"
            variant="outline-primary"
            size="sm"
            @click="showCreateDescription = true"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">New Description</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="10">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Payable To">
              <b-select
                :options="payableOpts"
                v-model="expense.payable"
                :state="errors.length > 0 ? false : null"
                :disabled="payableOpts.length < 1"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col class="d-flex justify-content-center align-items-center" sm="2">
          <b-button
            class="text-nowrap"
            variant="outline-primary"
            size="sm"
            @click="showPayableTo = true"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">New Payable To</span>
          </b-button>
        </b-col>
        <!-- <b-col>
          
          <b-form-group label="Payable To">
            <b-form-input v-model="expense.payable" />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group label="Type">
            <div class="d-flex justify-content-center" style="gap: 1rem">
              <b-form-radio name="type" v-model="expense.type" value="card"
                >Card</b-form-radio
              >
              <b-form-radio name="type" v-model="expense.type" value="check"
                >Check</b-form-radio
              >
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group :label="`Available ${expense.type}s`">
              <b-form-select
                :options="payOpts"
                v-model="expense.payId"
                :state="errors.length > 0 ? false : null"
                :disabled="payOpts.length < 1"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col class="d-flex justify-content-center align-items-center" sm="2">
          <b-button
            class="text-nowrap"
            variant="outline-primary"
            size="sm"
            @click="typeCreator"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle"
              >New {{ expense.type != "check" ? "Card" : "Check" }}</span
            >
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Transaction Id">
              <b-form-input
                v-model="expense.transaction"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            rules="required|validate-amount"
            v-slot="{ errors }"
          >
            <b-form-group label="Payment Amount $">
              <money
                v-bind="vMoney"
                v-model="expense.payAmount"
                class="form-control input-form"
                :class="[
                  errors.length > 0 && vmoneyValidate ? 'border-danger' : null,
                ]"
              ></money>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Status">
              <b-form-select
                :options="statusOpts"
                v-model="expense.status"
                :disabled="statusOpts.length < 1"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col>
          <b-form-group label="Date">
            <b-form-datepicker
              id="example-datepicker"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              v-model="expense.date_payment"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>
    <!-- To trigger computed changes -->
    {{ loadTypeOptions }} {{ updateSelectFields }}
    <!-- Modals -->
    <create-description-modal
      :show="showCreateDescription"
      @closing="showCreateDescription = false"
      @refresDescriptions="getDescriptions()"
    />
    <create-payable-modal
      :show="showPayableTo"
      @closing="showPayableTo = false"
      @refresPayables="getPayables()"
    />
    <create-card-modal
      :show="showCreateCard"
      @closing="showCreateCard = false"
      @refreshCards="getCards()"
    />
    <create-check-modal
      :show="showCreateCheck"
      @closing="showCreateCheck = false"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import expensesService from "../expenses.service";

import CreateDescriptionModal from "./CreateDescriptionModal.vue";
import CreatePayableModal from "./CreatePayableModal.vue";
import CreateCardModal from "./CreateCardModal.vue";
import CreateCheckModal from "./CreateCheckModal.vue";
import { VMoney } from "v-money";
import useUpdater from "../useUpdater";
const { shouldUpdate, field } = useUpdater();

export default {
  directives: { money: VMoney },
  props: {
    show: Boolean,
    update: Boolean,
    id: Number,
    uExpense: Object,
  },
  components: {
    CreateDescriptionModal,
    CreatePayableModal,
    CreateCardModal,
    CreateCheckModal,
  },
  data() {
    return {
      vmoneyValidate: false,
      accountFindError: false,
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      isActive: false,
      showCreateDescription: false,
      showPayableTo: false,
      showCreateCard: false,
      showCreateCheck: false,
      expense: {
        account: null,
        client: null,
        clientId: null,
        description: null,
        payable: null,
        type: "card",
        payId: null,
        transaction: null,
        payAmount: 0,
        status: null,
        date_payment: "",
      },
      descriptionOpts: [],
      payableOpts: [],
      payOpts: [],
      statusOpts: [],
    };
  },
  methods: {
    handleHidden() {
      this.expense = {
        account: null,
        client: null,
        clientId: null,
        description: null,
        payable: null,
        type: "card",
        payId: null,
        transaction: null,
      };
      this.vmoneyValidate = false;
      this.descriptionsOpts = [];
      this.payOpts = [];
      this.statusOpts = [];
      this.$emit("closing");
      this.vmoneyValidate = false;
      this.isActive = false;
      this.accountFindError = false;
    },
    async handleOk(ev) {
      var data = 1;
      ev.preventDefault();
      if (this.update) {
        data = await this.updateExpense();
      } else {
        data = await this.createExpense();
      }
      if (data === 0) {
        return;
      }
      this.isActive = false;
      this.$emit("closing");
    },
    typeCreator() {
      if (this.expense.type != "check") {
        this.showCreateCard = true;
      } else {
        this.showCreateCheck = true;
      }
    },
    async findAccount() {
      if (!!this.expense.account) {
        this.addPreloader();
        let res = await expensesService.getExpensesUser(this.expense.account);
        if (res.length < 1) {
          this.accountFindError = true;
          this.showErrorSwal("User not found");
          this.removePreloader();
          return;
        }
        this.accountFindError = false;
        this.expense.client = res[0].name_client;
        this.expense.clientId = res[0].client_account_id;
        this.removePreloader();
      }
    },
    async getDescriptions() {
      let res = await expensesService.getDescriptions();

      this.descriptionOpts = res.map((description) => ({
        value: description.id,
        text: description.description,
      }));
    },
    async getPayables() {
      let res = await expensesService.getPayables();

      this.payableOpts = res.map((description) => ({
        value: description.id,
        text: description.description,
      }));
    },
    async getCards() {
      this.addPreloader();
      let res = await expensesService.getCards();
      this.payOpts = res.map((card) => ({
        value: card.id,
        text: card.value,
      }));
      this.removePreloader();
    },
    async getChecks() {
      this.addPreloader();
      let res = await expensesService.getChecks();
      this.payOpts = res.map((check) => ({
        value: check.id,
        text: check.value,
      }));
      this.removePreloader();
    },
    async getStatus() {
      this.addPreloader();
      let res = await expensesService.getStatus();
      this.statusOpts = res.map((status) => ({
        value: status.id,
        text: status.name_status,
      }));
      this.removePreloader();
    },
    async createExpense() {
      this.vmoneyValidate = true;
      let isValid = await this.$refs["form"].validate();
      if (!isValid) {
        return 0;
      } else {
        this.vmoneyValidate = false;
        await expensesService.createExpense(
          this.expense.clientId,
          this.expense.description,
          this.expense.payable,
          this.expense.payAmount,
          this.expense.transaction,
          this.expense.type,
          this.expense.payId,
          this.currentUser.user_id,
          this.expense.status,
          this.expense.date_payment
        );
      }
      this.$emit("expense-created");
    },
    async updateExpense() {
      this.vmoneyValidate = true;
      let updating = await this.showConfirmSwal("Update Expense");
      if (!updating.isConfirmed) {
        return 0;
      } else {
        this.vmoneyValidate = false;
        await expensesService.updateExpense(
          this.uExpense.expense_id,
          this.expense.clientId,
          this.expense.description,
          this.expense.payable,
          this.expense.payAmount,
          this.expense.transaction,
          this.expense.type,
          this.expense.payId,
          this.currentUser.user_id,
          this.expense.status,
          this.expense.date_payment
        );
      }
      this.$emit("expense-created");
    },
    async getExpenseToUpdate() {
      this.expense = {
        account: this.uExpense.account,
        client: this.uExpense.client_name,
        clientId: this.uExpense.id_account,
        description: this.uExpense.id_description_expense,
        payable: this.uExpense.payable_id,
        type: this.uExpense.method_payment == "E-CHECK" ? "check" : "card",
        payId: this.uExpense.mp_id,
        transaction: this.uExpense.transaction_id,
        payAmount: this.uExpense.payment,
        status: this.uExpense.status_id,
        date_payment: this.uExpense.date_payment,
      };
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    loadTypeOptions() {
      if (!this.show) return;
      if (this.expense.type != "card") {
        this.getChecks();
        return;
      }
      this.getCards();
      return;
    },
    updateSelectFields() {
      if (shouldUpdate.value) {
        switch (field.value) {
          case "description":
            this.getDescriptions();
            break;
          case "card":
            this.getCards();
            break;
          case "check":
            this.getChecks();
          case "payable":
            this.getPayables();
            break;
        }
        return;
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        if (this.update) this.getExpenseToUpdate();
        this.getStatus();
        this.getDescriptions();
        this.getPayables();
        this.isActive = true;
      }
    },
  },
};
</script>
