<template>
  <b-modal
    title="CREATE DESCRIPTION"
    size="sm"
    centered
    modal
    v-model="isActive"
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @show="handleShow"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Create"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Name">
              <b-form-input
                v-model="name"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import expensesService from "../expenses.service";
import useUpdater from "../useUpdater.js";

const { shouldUpdate, field } = useUpdater();

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: false,
      name: null,
    };
  },
  methods: {
    handleShow() {
      // reset data
    },
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      this.createDescription();
      shouldUpdate.value = Symbol();
      field.value = "description";
      this.isActive = false;
    },
    handleHidden() {
      // reset
      this.$emit("closing");
      this.isActive = false;
    },
    async createDescription() {
      try {
        this.addPreloader();
        let res = await expensesService.createDescription(this.name);
        this.$emit("refresDescriptions");
        this.$emit("closing");
        this.name = null;
        this.removePreloader();
      } catch (e) {
        this.name = null;
        console.error(e.message);
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
      }
    },
  },
};
</script>
