<template>
  <div>
    <b-col class="text-right mt-2" align-self="end">
      <b-button variant="success" @click="createExpense"
        ><feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />Create Expense</b-button
      >
    </b-col>

    <filter-slot
      :filter="filters"
      :filter-principal="mainFilter"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      @reload="$refs['expenses-table'].refresh()"
    >
      <template #table>
        <b-table
          style="min-height: 12rem"
          ref="expenses-table"
          responsive="sm"
          show-empty
          sticky-header="50vh"
          :fields="fields"
          :items="tableProvider"
          class="blue-scrollbar"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :sort-desc.sync="sort_desc"
          small
        >
          <template v-slot:cell(ending_card)="data">
            {{
              data.item.method_payment === "CREDIT CARD"
                ? "XXXX-XXXX-XXXX-" + data.item.ending_card
                : "E-CHECK " + data.item.ending_card
            }}
          </template>

          <template v-slot:cell(created_by_user)="data">
            <p>{{ data.item.created_by_user }}</p>
            <p>{{ data.item.created_at | myGlobalWithHour }}</p>
          </template>

          <template v-slot:cell(date_payment)="data">
            <p>{{ data.item.date_payment | myGlobal }}</p>
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td colspan="2"></b-td>
              <b-td colspan="2" class="text-right font-weight-bolder py-px">
                Sub-total
              </b-td>
              <b-td colspan="1" class="text-left py-px">
                <b-badge class="w-100">{{
                  "$ " + partialTotal.toFixed(2)
                }}</b-badge>
              </b-td>
              <b-td colspan="10" />
            </b-tr>
            <b-tr>
              <b-td colspan="2"></b-td>
              <b-td colspan="2" class="text-right font-weight-bolder py-px">
                Total
              </b-td>
              <b-td colspan="1" class="text-left py-px">
                <b-badge class="w-100">{{ "$ " + paySumatory }}</b-badge>
              </b-td>
              <b-td colspan="10" />
            </b-tr>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <router-link
                class
                v-if="moduleId === 3"
                target="_blank"
                :to="`/bussiness/clients/account/${data.item.id_account}`"
              >
                {{ data.value }}
              </router-link>
            </div>
          </template>
          <template #cell(payment)="data">
            <span>${{ data.value }}</span>
          </template>
          <template #cell(name_status)="data">
            <span
              class="badget-wo-color"
              :style="{
                backgroundColor: `rgba(${hexToRgb(
                  data.item.color_status
                )}, 0.2)`,
                color: data.item.color_status,
              }"
            >
              {{ data.value }}
            </span>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.value | myGlobalDay }}</span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown size="sm" variant="link" no-caret dropleft>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="updateExpense(data.item)">
                <feather-icon icon="EditIcon" class="text-primary" />
                Update</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  getPayDetails(data.item.mp_id, data.item.method_payment)
                "
              >
                <feather-icon icon="EyeIcon" class="text-info" />
                Show Pay Detail</b-dropdown-item
              >
              <b-dropdown-item @click="getTracking(data.item.expense_id)">
                <feather-icon icon="ClockIcon" class="text-warning" />
                History</b-dropdown-item
              >
              <b-dropdown-item @click="deleteExpense(data.item.expense_id)">
                <feather-icon icon="TrashIcon" class="text-danger" />
                Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <tracking-modal
      type="expense"
      :id="activeExpense"
      :show="showTrackingModal"
      @closing="showTrackingModal = false"
    />
    <details-modal
      :type="detailMethod"
      :id="detailPaymentId"
      :show="showDetailsModal"
      @closing="showDetailsModal = false"
    />
    <create-expense-modal
      :id="activeExpense"
      :uExpense="toUpdate"
      :update="isUpdating"
      :show="showCreateExpense"
      @expense-created="$refs['expenses-table'].refresh()"
      @closing="showCreateExpense = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import expensesService from "../expenses.service"

import TrackingModal from "./TrackingModal.vue"
import DetailsModal from "./DetailsModal.vue"
import CreateExpenseModal from "./CreateExpenseModal.vue"

export default {
  components: {
    TrackingModal,
    DetailsModal,
    CreateExpenseModal,
  },
  data() {
    return {
      sort_desc: true,
      filters: [
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Description",
          model: null,
          options: [],
          reduce: "id",
          selectText: "description",
          cols: 12,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Status",
          model: null,
          options: [],
          reduce: "id",
          selectText: "name_status",
          cols: 12,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Payment Type",
          model: null,
          options: [
            { id: 1, name: "CREDIT CARD" },
            { id: 2, name: "E-CHECK" },
          ],
          reduce: "id",
          selectText: "name",
          cols: 12,
        },
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
      ],
      mainFilter: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      fields: [
        {
          key: "account",
          label: "Account",
          sortable: false,
        },
        {
          key: "client_name",
          label: "Client Name",
          sortable: false,
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
        },
        {
          key: "payable_to",
          label: "Payable To",
          sortable: false,
        },
        {
          key: "payment",
          label: "Payment",
          sortable: false,
        },
        {
          key: "name_status",
          label: "Status",
          sortable: false,
        },
        {
          key: "created_by_user",
          label: "Created by",
          sortable: false,
        },
        {
          key: "date_payment",
          label: "Payment Date",
          sortable: false,
        },
        {
          key: "transaction_id",
          label: "Transaction Id",
          sortable: false,
        },
        {
          key: "method_payment",
          label: "Payment Method",
          sortable: false,
        },
        {
          key: "ending_card",
          label: "Ending",
          sortable: false,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
      paginate: { currentPage: 1, perPage: 10 },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      showTrackingModal: false,
      showDetailsModal: null,
      showCreateExpense: false,
      isUpdating: false,
      toUpdate: {},
      activeExpense: -1,
      detailPaymentId: -1,
      detailMethod: "",
      paySumatory: 0,
      partialTotal: 0,
    }
  },
  methods: {
    async tableProvider(ctx) {
      let body = {
        name_text: this.mainFilter.model,
        from: this.filters[3].model,
        to: this.filters[4].model,
        page: this.paginate.currentPage,
        perPage: this.paginate.perPage,
        order: ctx.sortDesc ? "desc" : "asc",
        orderby: 10,
        user_created_by: null,
        description: this.filters[0].model,
        status_id: this.filters[1].model,
        mpayment: this.filters[2].model,
        module_id: this.moduleId,
        is_deleted: this.$route.meta.isDeletedTab,
      }
      let res = await expensesService.getExpenses(body)
      if (res.total < 1) {
        this.totalRows = 0
        this.startPage = 0
        this.toPage = 0
        this.paySumatory = 0
        return []
      }
      this.totalRows = res.total
      this.startPage = res.from
      this.toPage = res.to
      this.paySumatory = res.data[0].pp
      this.partialTotal = res.data.reduce(
        (prev, curr) => prev + Number(curr.payment),
        0
      )
      return res.data || []
    },
    createExpense() {
      this.isUpdating = false
      this.activeExpense = -1
      this.showCreateExpense = true
    },
    updateExpense(expense) {
      this.isUpdating = true
      this.toUpdate = expense
      this.showCreateExpense = true
    },
    getTracking(id) {
      this.activeExpense = id
      this.showTrackingModal = true
    },
    getPayDetails(payId, method) {
      this.detailPaymentId = payId
      if (method != "E-CHECK") {
        this.detailMethod = "card"
      } else {
        this.detailMethod = "check"
      }
      this.showDetailsModal = true
    },
    async deleteExpense(id) {
      let deleting = await this.showConfirmSwal("Delete Expense")
      if (!deleting.isConfirmed) return
      await expensesService.deleteExpense(id, this.currentUser.user_id)
      this.$refs["expenses-table"].refresh()
    },
    async setFilterOptions() {
      this.filters[0].options = await expensesService.getDescriptions()
      this.filters[1].options = await expensesService.getStatus()
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e
      this.tableProvider()
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  created() {
    this.setFilterOptions()
  },
}
</script>
<style scoped>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
